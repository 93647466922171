.terms-page-main {
    margin-top: 75px;

    h1 {
        color: #363738;
        text-align: center;
        font-size: 3.5rem;
        margin: 20px 0;
    }

    .content-wrapper {
        height: 90vh;
        overflow: auto;
        border: 1px solid #b0b0b0;
        max-width: 1000px;
        margin: 30px auto;
        padding: 20px;
    }

    .right-reserved {
        text-align: center;
    }

    u, li, p {
        margin-bottom: 10px;
    }

    li:nth-child(1) {
        margin-top: 10px;
    }

    ol {
        padding: 0;
    }

    a {
        color: #0000FF;
        text-decoration: underline;

    }

    table {
        margin: 25px 0;
    }

    td {
        padding: 5px;
    }
}
