@import '../../../shared/common.scss';

//TODO $description-font-weight in all files

.landing-wrapper {
    .ecosystem-section {
        min-height: 100vh;
        background-image: linear-gradient(
                180deg,
                rgba(17, 18, 18, 1) 0%,
                rgba(255, 255, 255, 0) 50%,
                rgba(17, 18, 18, 1) calc(100% - 50px),
                rgba(17, 18, 18, 1) 100%
            ),
            url('./empty-flat-interior-1.png');
        background-position: center;
        background-size: cover;
        color: $text-color-light;
        padding: 0rem 2rem 3.5rem 2rem;
        text-align: center;
        position: relative;
        h3 {
            margin-bottom: 2.5rem;
        }

        .description {
            font-size: 1.1rem;
            font-weight: 300;
            line-height: 27px;
            max-width: 400px;
            margin: 0 auto;
        }

        .center-flex-wrapper {
            justify-content: center;
            height: 100%;
        }
        .center-wrapper {
            margin: 8rem auto 0 auto;
            background: rgba($color: $card-background, $alpha: 0.7);
            width: 300px;
            height: 300px;
            border: 1px solid #fff;
            border-radius: 50%;
            position: relative;

            .center-img-wrapper {
                position: absolute;
                @include absoluteCenterPosition;
            }

            .card-wrapper {
                position: absolute;

                .card-icon-wrapper {
                    border: 1px solid #fff;
                    background: $card-background;
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                    position: relative;
                    transition: all 0.3s;

                    img {
                        position: absolute;
                        @include absoluteCenterPosition;
                    }

                    @media screen and (min-width: 800px) {
                        &:hover {
                            cursor: pointer;
                            transform: scale(1.2);
                        }
                    }
                }

                .card-content {
                    position: absolute;
                    width: 280px;

                    h5 {
                        font-size: $card-title-font-size;
                        margin-bottom: 1rem;
                    }

                    p {
                        font-size: $description-font-size;
                        font-weight: $description-font-weight;
                    }
                }
            }
            .card-wrapper.users {
                top: -4px;
                left: 18px;

                .card-content {
                    right: 80px;
                    top: 5px;
                }
            }

            .card-wrapper.groups {
                top: 148px;
                left: -31px;

                .card-content {
                    left: -299px;
                    top: 2px;
                }
            }

            .card-wrapper.areas {
                bottom: -25px;
                left: 119px;

                .card-content {
                    left: -112px;
                    width: 290px;
                }
            }

            .card-wrapper.devices {
                top: -6px;
                right: 18px;

                .card-content {
                    width: 286px;
                    left: 80px;
                    top: 5px;
                }
            }

            .card-wrapper.access-points {
                top: 148px;
                right: -31px;

                .card-content {
                    width: 300px;
                    right: -318px;
                    top: 2px;
                }
            }

            .card-wrapper.users,
            .card-wrapper.groups {
                text-align: right;
            }

            .card-wrapper.devices,
            .card-wrapper.access-points {
                text-align: left;
            }
        }

        .overlay {
            transition: opacity 0.2s;
            position: fixed;    
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(98, 98, 98, 0.5);
            z-index: 999;
            overflow: hidden;
        }

        .overlay.hidden {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .ecosystem-hover-image {
            position: absolute;
            transition: opacity 0.4s;
            opacity: 1;
            border-radius: 13px;
            box-shadow: -6px 4px 19px -13px rgba(0,0,0,0.75);
            -webkit-box-shadow: -6px 4px 19px -13px rgba(0,0,0,0.75);
            -moz-box-shadow: -6px 4px 19px -13px rgba(0,0,0,0.75);
        }
        
        .ecosystem-hover-image.horizontal{
            width: 33%;

        }

        .ecosystem-hover-image.vertical{
            width: 25%;
        }

        .ecosystem-hover-image.devices-left{
            left: 15%;
        }
        .ecosystem-hover-image.devices-middle {
            left: 37%;
        }
        .ecosystem-hover-image {
            opacity: 0.9;
        }

        .image-1 {
            top: 23%;
            left: 26%;
            z-index: 1000;
        }

        .image-2 {
            top: 14%;
            left: 42%;
            z-index: 1001;
        }

        .image-3 {
            top: 32%;
            left: 58%;
            z-index: 1001;
        }

        .ecosystem-hover-image.hidden {
            opacity: 0;
        }

        @media screen and (max-width: 1000px) {
            h3 {
                font-size: 2.5rem;
            }
            .center-img-wrapper {
                display: none;
            }

            .center-wrapper {
                background: initial;
                border: none;
                margin: 30px auto;
                height: auto;
                display: flex;
                flex-direction: column;

                .card-wrapper {
                    position: initial;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 15px;

                    .card-content {
                        position: initial;
                        text-align: left;
                    }

                    .card-icon-wrapper {
                        width: 70px;
                    }
                }

                .card-wrapper.devices {
                    order: 5;
                }
            }

            .description {
                margin-bottom: 70px;
            }
        }

        @media screen and (max-width: 550px) {
            .center-wrapper {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin: 30px 0;
            }
        }

        @media screen and (max-height: 750px) {
            .center-wrapper {
                margin-top: 50px;
            }
        }
    }
}
