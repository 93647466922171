@import '../../../shared/common.scss';

.contact-us-section {
    background-color: $background-color-dark-grey;
    color: $text-color-light;
    padding: 10px;
    max-width: 550px;
    h3 {
        text-align: center;
        font-size: 1.7rem;
        margin-bottom: 10px;
    }
    form {
        // width: 50%;
        max-width: 650px;
        margin: 0 auto;
        flex-direction: column;
        display: flex;

        gap: 20px;

        .name-section {
            gap: 1rem;

            div {
                flex-grow: 1;
            }
        }

        button {
            border-radius: 7px;
            font-size: 1.2em;
            font-weight: 400;
            padding: 9px 18px;
        }

        .MuiSwitch-root {
            margin-left: 0;
            margin-right: 10px;
        }

        .MuiFormControlLabel-label {
            font-size: 0.9rem;
            font-weight: 300;
        }

        .MuiFormControlLabel-root {
            margin: 0px;
        }
    }

    @media screen and (max-width: 900px) {
        form {
            width: 90%;
        }
    }

    @media screen and (max-width: 580px) {
        form {
            .name-section {
                flex-direction: column;
            }
        }
    }

    .concents-section {
        a {
            color: #fff;
            text-decoration: underline;
            &:hover {
                color: #d5d5d5;
            }
        }
        label {
            font-weight: 300;
        }
    }
}
