@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$font-main: 'Open Sans', sans-serif;

$header-font-size: 1rem;
$hero-demo-box-title-font-size: 0.9rem;
$hero-demo-box-chip-font-size: 0.6rem;
$hero-title-font-size: 4.1rem;
$hero-subtitle-font-size: 2.6rem;
$description-font-size: 1.2rem;
$description-font-weight: 300;
$card-title-font-size: 1.8rem;

$landing-section-padding-top: 10rem;
$landing-page-top-padding: 75px;

$accent-text-blue-color-light: #1b1d1e;
$accent-text-blue-color-dark: #31adff;
$text-color-light: #fff;
$text-color-blue: #31adff;
$text-color-dark: #000;
$hero-background-color: #474b51;
$border-color-blue: #2491ff;
$shelly-blue: #31ADFF;
$box-shadow-color-blue: #2491ff66;
$hero-chip-background-color: #34373a;
$button-black-background: #141414;
$background-color-dark-grey: #111213;
$card-background: #363738;
$selected-card-background: #31adff;
$popup-overlay-color: rgba(98, 98, 98, 0.5);
$error-color: #cc1212;

@mixin section-title {
    font-size: 2.9rem;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
}

@mixin form {
    border: 1px solid black;
    max-width: 25%;
    margin: 0 auto;
    padding: 10px 30px;
    flex-direction: column;
    gap: 10px;
}

@mixin btn {
    border: none;
    border-radius: 62px;
    padding: 13px 18px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;

    &:hover {
        cursor: pointer;
    }
}

@mixin btn-white {
    @include btn;

    color:  $shelly-blue;
    background: transparent;
    border: 1px solid #fff;
    background-color: #fff;

    &:hover {
        background-color: transparent;
        color:  #fff;
        border: 1px solid #fff;
    }
}

@mixin btn-blue-white {
    @include btn;

    color: $text-color-light;
    background: $shelly-blue;
    border: 1px solid $shelly-blue;
    
    &:hover {
        background-color: transparent;
        // color:  $shelly-blue;
        border: 1px solid #fff;
    }
}

@mixin btn-blue {
    @include btn;

    color: $text-color-light;
    background: $shelly-blue;
    border: 1px solid $shelly-blue;

    &:hover:not(:disabled) {
        background-color: transparent;
        color:  $shelly-blue;
        border: 1px solid $shelly-blue;
    }

    &:disabled {
        opacity: 0.5;
    }
}

@mixin btn-black {
    @include btn;

    color: $text-color-light;
    background-color: $button-black-background;

    &:hover {
        background: #2f2f2f;
    }
}

@mixin input {
    border: 1px solid #ffffff;
    background: linear-gradient(0deg, #363738, #363738);
    border-radius: 8px;
    padding: 9px 13px;
    opacity: 0.5px;
    color: #fff;
    font-weight: 300;
    outline: none;
    transition: 0.2s;

    &:focus {
        border: 1px solid #a8a8a8;
    }

    &:disabled {
        opacity: 0.4;
    }
}

@mixin absoluteCenterPosition {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin cardBorder {
    border: 1px solid #ffffff;
    border-radius: 12px;
}

@mixin form-btn {
    @include btn-blue;
    border-radius: 7px;
}

@mixin red-form-btn {
    @include btn;
    background-color: #fb1717;
    color: #fff;
    border-radius: 7px;

    &:hover {
        background-color: #e10b0b;
    }
}

@mixin cancel-btn {
    @include btn;
    border-radius: 7px;
}

@mixin delete-btn {
    @include btn;
    background-color: #e12735;
    color: #fff;
    border-radius: 7px;
}


@mixin white-overlay {
    transition: opacity 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(98, 98, 98, 0.5);
    z-index: 999;
    overflow: hidden;
}

