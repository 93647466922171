@import '../../../shared/common.scss';

@import '../../../shared/common.scss';

.landing-wrapper {
    .roles-and-access {
        background: #15161740;
        background: $background-color-dark-grey;
        color: $text-color-light;
        text-align: center;
        padding: $landing-section-padding-top 2rem 16rem 2rem;

        h3 {
            margin-bottom: 1.5rem;
        }

        p {
            font-size: $description-font-size;
            font-weight: 300;
            line-height: 27px;
        }

        .cards-wrapper {
            justify-content: center;
            gap: 2rem;
            margin-top: 6rem;

            .roles-card {
                border: 1px solid #fff;
                background-color: $card-background;
                border-radius: 12px;
                width: 355px;
                display: flex;
                flex-direction: column;
                gap: 1.7rem;
                padding: 20px;
                height: 100%;
                &.horizontal {
                    flex-direction: row;
                    padding: 2rem;
                    align-items: center;
                    text-align: left;
                    img {
                        width: 60px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                h4 {
                    font-size: 1.8rem;
                    font-weight: 600;
                    line-height: 48px;
                }
            }

            .roles-card-wrapper.horizontal-wrapper:nth-child(4),
            .roles-card-wrapper.horizontal-wrapper:nth-child(5) {
                display: none;
            }

            .roles-card-wrapper {
                position: relative;
            }
            
            .roles-card:nth-child(3) .roles-card-img-wrapper {
                margin-bottom: 14px;
            }

            .roles-card-img-wrapper.technician {
                height: 63px;
            }   
        }

        .cards-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1em;
        }

        @media screen and (max-width: 1200px) {
            padding: 10rem 10px 16rem 10px;
            .cards-wrapper {
                gap: 10px;

                .roles-card {
                    padding: 80px 10px;
                }

                .roles-card.horizontal {
                    padding: 30px 10px;
                }
            }
        }

        @media screen and (max-width: 980px) {
            .cards-wrapper {
                .roles-card:not(.horizontal) {
                    display: none;
                }

                .roles-card-wrapper.horizontal-wrapper:nth-child(4),
                .roles-card-wrapper.horizontal-wrapper:nth-child(5)  {
                    display: flex;
                }
            }
        }


        @media screen and (max-width: 400px) {
            .cards-wrapper {
                .roles-card:not(.horizontal) {
                    display: none;
                }

                .roles-card.horizontal:nth-child(4),
                .roles-card.horizontal:nth-child(5) {
                    display: flex;
                }

                .roles-card.roles-card.horizontal {
                    // width: auto;
                    img {
                        width: 45px;
                    }

                    h4 {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}

.roles-and-access-popup-content {
    color: #fff;
    margin: 10px;
    font-size: 19px;
    max-width: 300px;

    .close-icon:hover {
        cursor: pointer;
    }
}
