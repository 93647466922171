.my-popover {
    color: #fff;
    border-radius: 6px;
    background: #1c1b1b;
    opacity: 1;
    padding: 10px;
    position: absolute;
    right: -190px;
    top: 20px;
    transition: opacity .3s ease-in-out;
    width: 280px;
    z-index: 2;
}

.my-popover.popover-hidden {
    opacity: 0;
    z-index: -1;
}

@media screen and (max-width: 910px) {
    .cards-wrapper .my-popover {
        right: -180px;
    }
}

@media screen and (max-width: 710px) {
    .cards-wrapper .my-popover {
        right: -80px;
    }
}


@media screen and (max-width: 510px) {
    .cards-wrapper .my-popover {
        top: 5px;
        right: 10px;
    }
}
