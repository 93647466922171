@import '../../shared/common.scss';



.setup-details-section {
    justify-content: center;
    align-items: center;

    .setup-wrapper {
        padding: 1rem;
        @include cardBorder;
        background-color: $card-background;
        width: 30%;
        min-width: 300px;
        max-height: 460px;
    }

    h3 {
        text-align: center;
        font-size: 1.8rem;
    }

    p.section-title {
        margin-bottom: 10px;
    }
    button {
        padding: 9px 18px;
        border-radius: 7px;
    }

    .btn-blue-white {
        width: 100%;
    }

    input {
        width: 100%;
    }

    .sections-wrapper {
        width: 100%;
        max-width: 1200px;
    }

    .payment-details {
        text-align: center;

        .bold {
            font-weight: 700;
        }

        .light-grey-section {
            background-color: #626469;
            border-radius: 8px;
            padding: 10px;
        }

        .light-grey-section.status {
            height: 100%;
        }
    }

    .table-wrapper {
        overflow: auto;
        scrollbar-width: thin; 
        scrollbar-color: #888 #f1f1f1; 
        
        table {
            border-collapse: collapse;
            width: 100%;
        }

        td{
            padding: 5px;
        }

        td:nth-child(1),
        td:nth-child(2),
        th:nth-child(1),
        th:nth-child(2) {
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }

        td:nth-child(3),
        th:nth-child(3) {
            border-bottom: 1px solid #fff;
        }

        tr:last-child {
            td:nth-child(1),
            td:nth-child(2),
            td:nth-child(3) {
                border-bottom: none;
            }
        }

        tr:hover {
            cursor: pointer;
            background-color: #626469;
        }
    }
    
    ::-webkit-scrollbar {
        width: 10px;
        margin-left: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width: 970px) {
        .sections-wrapper {
            flex-direction: column;
            align-items: center;
        }
    }
}

.deactivate-setup-popup {
    p {
        font-size: 18px;
    }

    button {
        padding: 10px 12px;
    }

    .delete-btn:disabled {
        background: #dd9a9f;
    }
}

.auth-setting-popup,
.add-remove-domain-popup {
    h2 {
        white-space: nowrap;
    }
    h2,
    div {
        color: #fff;
    }

    button {
        flex-grow: 1;
    }

    .MuiSelect-icon,
    .MuiSvgIcon-root {
        color: #fff;
    }

    .MuiSelect-select {
        border-bottom: 1px solid;

        .css-74e2me-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(
                .Mui-disabled,
                .Mui-error
            )::before {
            border-bottom: 1px solid #fff;
        }
        &:hover {
            border-bottom: 1px solid #fff;
        }
    }

    .add-remove-domain-btn {
        border: 1px solid #fff;
        padding: 2px;
        margin-bottom: 3px;
    }
}

.domain-name {
    background: linear-gradient(0deg, #363738, #363738);
    border-radius: 8px;
    padding: 9px 13px;
    flex-grow: 1;
}

// #setup-billing-info {
//     font-size: 18px !important;
//     }

#setup-billing-info {
    .MuiTooltip-tooltip {
        font-size: 17px !important;
        background-color: #fff;
        color: #000;
    }
}

.pay-btn {
    font-size: 15px;
}
