@import '../../shared/common.scss';

.setup-card {
    @include cardBorder;

    background-color: $card-background;
    padding: 20px 30px;
    font-size: 2rem;
    font-weight: 300;
    transition: all 0.2;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}
