.input-wrapper {
    label {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-left: 5px;

        &.label-disabled {
            opacity: 0.5;
        }
    }
}
