@import '../../shared/common.scss';

div.auth{
    padding:  calc(75px + 2rem) 2rem 2rem 2rem;
    flex-grow: 1;
    background: $background-color-dark-grey;
    color: $text-color-light;

}

.bms-tenants {
    gap: 25px;
    flex-wrap: wrap;
    .MuiSelect-select {
        background-color: #34373a;
    }

    .MuiFormLabel-root {
        color: #fff;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 2px solid #fff;
    }

    svg{
        color: #fff;
    }
}