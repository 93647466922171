@import '../../../shared/common.scss';

.landing-wrapper {
    .the-future-section-wrapper {
        padding: 19rem 1rem 4rem 1rem;

        #about {
            margin-bottom: 70px;
        }
        .content-wrapper {
            max-width: 900px;
            margin: 0 auto;
            text-align: center;
            display: flex;
            flex-direction: column;

            div:nth-child(1) {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-bottom: 10px;
            }

            p {
                line-height: 27px;
                font-size: $description-font-size;
            }
        }

        h3 {
            font-weight: 600;
        }

        @media screen and (max-width: 1400px) {
            .content-wrapper {
                width: 70%;
            }
        }

        @media screen and (max-width: 700px) {
            padding-top: 14rem;

            .content-wrapper {
                width: 100%;      
                
                p{
                    font-size: 1rem;
                }
            }

            h3 {
                font-size: 2.6rem;
            }
        }
        @media screen and (max-width: 390px) {
            h3 {
                font-size: 2.2rem;
            }
        }
    }
}
