@import '../../../shared/common.scss';

.register-popup-wrapper {
    background: $background-color-dark-grey;
    color: #fff;
    padding: 30px;
    transition: all 0.4s;

    .register-plan-title {
        font-weight: 500;
        font-size: 2rem;
        white-space: nowrap;
    }

    .registered-view {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}