@import '../../shared/common.scss';

footer {
    background: $card-background;
    padding: 60px 20px;

    li:hover {
        cursor: pointer;
    }

    a {
        color: rgba($color: #fff, $alpha: 0.8);
        font-weight: 300;
    }

    .width-wrapper {
        max-width: 1200px;
        margin: 0px auto;
        align-items: center;

        .logo-section {
            margin-right: 150px;

            ul {
                justify-content: space-between;
                align-items: center;
                img {
                    max-width: 30px;
                }

                a:nth-child(2) img {
                    max-width: 20px;
                }
                a {
                    transition: opacity 0.2s;
                }
                a:hover {
                    opacity: 0.7;
                }
            }

            .shelly-logo {
                width: 240px;
            }
        }

        .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            flex-grow: 1;

            li {
                margin-bottom: 14px;
            }

            a {
                transition: color 0.2s;
            }

            a:hover {
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    @media screen and (max-width: 850px) {
        .width-wrapper {
            .logo-section {
                margin-right: 20px;
            }
        }
    }

    @media screen and (max-width: 680px) {
        padding: 20px;

        .width-wrapper {
            flex-direction: column;
            .logo-section {
                margin-right: 20px;
            }

            .grid-container {
            }
        }
    }

    @media screen and (max-width: 440px) {
        padding: 50px;

        .width-wrapper {
            align-items: first baseline;
            .grid-container {
                display: block;
            }
        }
    }
}
