@import '../../../shared/common.scss';

.get-started-learn-more-section {
    background: $background-color-dark-grey;
    color: $text-color-light;

    .learn-more-wrapper {
        background-image: url('./learn-more.png');
        background-position-y: bottom;
        background-size: cover;
    }

    .learn-more {
        background-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(0, 0, 0, 0) 25%
            ),
            linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 74%,
                rgba(17, 18, 19, 0.8883928571428571) 100%
            );
        // background-color: green;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding: 10rem 0;

        h3 {
            margin: 0 10px;
            font-weight: 400;
            max-width: 700px;
            text-align: center;
        }
    }

    .get-started {
        .get-started-description {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            // max-width: 50%;
            margin: 0 auto;
            margin-bottom: 0px;
            text-align: center;
            margin-bottom: 5rem;

            h5 {
                font-size: 22px;
                font-weight: 700;
                line-height: 35px;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: $description-font-size;
                font-weight: 300;
                line-height: 29px;
            }
        }

        .description-section {
            justify-content: center;
            margin: 0 auto;
            margin-top: 3rem;
            gap: 2rem;
            position: relative;

            .registration-wrapper {
                margin-top: 33px;
                margin-bottom: 12px;
                h5 {
                    margin-top: 11px;
                }
            }

            .line-detail {
                position: absolute;
            }

            .line-detail.right {
                right: 194px;
                top: 40px;
            }

            .line-detail.left {
                left: 200px;
                top: 40px;
            }
        }
    }

    .mySwiper {
        display: none;
    }

    h3 {
        text-align: center;
        margin-bottom: 20px;
    }

    .plan-cards-wrapper {
        justify-content: center;
        align-items: center;
        gap: 0.9rem;
        padding: 10px;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 55%,
            rgba(255, 255, 255, 1) 90%
        );
    }

    .swiper-pagination {
        position: initial;
        margin-top: 10px;
    }

    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
        background: #fff;
    }

    .swiper-slide {
        background: $background-color-dark-grey;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 27% !important;
    }
    

    @media screen and (max-width: 1270px) {
        .get-started {
            .description-section {
                .line-detail.right {
                    right: 160px;
                }

                .line-detail.left {
                    left: 169px;
                }
            }
        }
        .carousel-wrapper {
            padding: 10px 20px;
        }

        .plan-cards-wrapper {
            display: none;
        }

        .mySwiper{
            display: block;
        }
    }

    @media screen and (max-width: 1110px) {
        .swiper-slide {
            width: 29% !important;
        }
    }

    @media screen and (max-width: 930px) {

        .swiper-slide {
            width: 37% !important;
        }
    }

    @media screen and (max-width: 850px) {
        .learn-more {
            h3 {
                font-size: 2.5rem;
            }
        }

        .get-started {
            .get-started-description {
                h5 {
                    font-size: 20px;
                }

                p {
                    font-size: 1rem;
                }
            }

            .description-section {
                .line-detail.right {
                    right: 124px;
                }

                .line-detail.left {
                    left: 130px;
                }
            }
        }
    }

    @media screen and (max-width: 700px) {
        .swiper-slide {
            width: 42% !important;
        }
    }

    @media screen and (max-width: 660px) {
        .learn-more {
            h3 {
                font-size: 2.2rem;
            }
        }

        .swiper-slide {
            width: 60% !important;
        }

        .get-started {
            h3{
                font-size: 2.4rem;
            }
        }

        .get-started {
            .description-section {
                flex-direction: column;
                .line-detail.right,
                .line-detail.left {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .swiper-slide {
            width: 80% !important;
        }
    }


    @media screen and (max-width: 355px) {
        .swiper-slide {
            width: 85% !important;
        }
    }
}
