@import '../../shared/common.scss';

header {
    padding: 10px 60px;
    font-size: $header-font-size;
    font-weight: 400;
    height: 75px;
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    transition: top 0.5s;
    // transition: top 0.3s;
    z-index: 1010;
    -webkit-box-shadow: 0px 10px 62px -23px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 10px 62px -23px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 10px 62px -23px rgba(0, 0, 0, 0.75);

    .header-img-wrapper {
        position: relative;
        // width: 133px;

        // img {
        //     position: absolute;
        //     top: 8px;
        // }
    }

    .register-btn {
        font-size: 15px;
        box-sizing: border-box;
        
        // &:hover {
        //     background-color: #fff;
        //     color:  $shelly-blue;
        //     border: 1px solid $shelly-blue;
        // }
    }

    ul {
        margin-left: 30px;
        margin-right: 50px;
        padding: 0;
        flex-grow: 1;
        justify-content: space-between;
        gap: 10px;

        li {
            list-style: none;
        }
    }

    .header-buttons-wrapper {
        gap: 20px;
        align-items: center;

        div {
            height: fit-content;
        }

        button,
        a {
            width: 100px;
            border-radius: 12px;
        }
    }

    .hamburger-menu {
        height: fit-content;
        align-self: center;
        display: none;

        &:hover {
            cursor: pointer;
        }
    }

    .hamburger-menu span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: $text-color-blue;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    .hamburger-menu span:first-child {
        transform-origin: 0% 0%;
    }

    .hamburger-menu span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    .hamburger-menu.open span {
        opacity: 1;
        transform: rotate(-45deg) translate(-3px, -1px);
    }

    .hamburger-menu.open span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .hamburger-menu.open span:nth-last-child(3) {
        transform: rotate(45deg) translate(0, -1px);
    }
}

.header-menu-link {
    transition: color 0.2s;

    &:hover {
        color: $accent-text-blue-color-dark;
    }
}

.toggle-menu-wrapper {
    position: fixed;
    z-index: 9;

    div {
        position: relative;
    }
}

.toggle-menu {
    position: absolute;
    z-index: 2;
    width: 100%;
    transform: translate(0, -100%);
    transition: 0.3s;
    background-color: #fff;
    margin: 0;
    text-align: center;
    width: 100vw;

    li {
        padding: 20px 0;
        border-bottom: 1px solid #e4e7eb;
    }
}

.toggle-menu.visible {
    transform: translate(0, 75px);
}

.auth-header {
    justify-content: space-between;

    ul {
        margin-left: 160px;
    }
}

@media screen and (max-width: 1250px) {
    header {
        justify-content: space-between;

        .large-screen-menu,
        .header-buttons-wrapper {
            display: none;
        }

        .hamburger-menu {
            display: block;
        }
    }
}

@media screen and (max-width: 700px) {
    header {
        padding: 10px;
    }
}

.btn-login {
    color: $shelly-blue;
    transition: 0.3s all;
    padding: 12px 17px;
    border: 1px solid rgba(49, 173, 255, 0);
}

.btn-login:not(.hamburger-menu-login):hover {
    border: 1px solid rgba(49, 173, 255, 1);
}

.hamburger-menu-login:hover {
    color: #0e9af8;
}

.nav-hidden {
    top: -110px;
    transition: top 0.5s;
}