@import '../../../shared/common.scss';

.landing-wrapper {
    .hero-section {
        padding-top: 75px;
        background-image: url('./hero-background.png');
        height: 80vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 75px);
        position: relative;

        .hero-content {
            justify-content: space-between;
            max-width: 1000px;
            text-align: center;
            margin: 0 auto;
            padding-top: 9rem;

            .title-section {
                h1 {
                    margin: 0;
                    font-size: $hero-title-font-size;
                    font-weight: 700;
                    color: $text-color-light;

                    span {
                        font-weight: 600;
                    }
                }

                .sub-title {
                    color: $text-color-light;
                    font-weight: 400;
                    font-size: $hero-subtitle-font-size;
                    margin: 10px 0 40px 0;
                }
            }

            .devices-img-section {
                position: absolute;
                bottom: -265px;
                left: 50%;
                transform: translateX(-50%);
                width: 1125px;
                img {
                    width: 100%;
                }
            }
        }

        .buttons-wrapper {
            display: none;
            button,
            a {
                width: 160px;
            }

            a:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: 1250px) {
            height: calc(80vh + 200px);
            .hero-content {
                width: 95%;
                height: 410px;
                .border-gradient-wrapper {
                    width: 45%;
                }
            }

            .buttons-wrapper {
                display: flex;
                justify-content: center;
                gap: 50px;
            }
        }

        @media screen and (max-height: 930px) {
            .hero-content {
                .devices-img-section {
                    width: 950px;
                    // bottom: -215px;
                    bottom: -230px;
                }
            }
        }

        @media screen and (max-width: 1090px), (max-height: 850px) {
            .hero-content {
                .devices-img-section {
                    width: 850px;
                    bottom: -215px;
                }
            }
        }

        @media screen and (max-width: 940px) {
            .hero-content {
                .title-section {
                    h1 {
                        font-size: 3.5rem;
                    }

                    .sub-title {
                        font-size: 2.1rem;
                        font-weight: 300;
                    }
                }
            }
        }

        @media screen and (max-width: 830px), (max-height: 750px) {
            .hero-content {
                .title-section {
                    h1 {
                        font-size: 3rem;
                    }

                    .sub-title {
                        font-size: 1.8rem;
                        font-weight: 300;
                    }
                }
                .devices-img-section {
                    width: 650px;
                    bottom: -160px;
                }
            }
        }

        @media screen and (max-width: 700px), (max-height: 550px) {
            .hero-content {
                .title-section {
                    h1 {
                        font-size: 2.1rem;
                    }

                    .sub-title {
                        font-size: 1.5rem;
                        font-weight: 300;
                    }
                }

                .devices-img-section {
                    width: 500px;
                    bottom: -125px;
                }
            }
        }

        @media screen and (max-width: 510px), (max-height: 460px) {
            .hero-content {
                .title-section {
                    margin: 0 auto;
                }
                .devices-img-section {
                    width: 350px;
                    bottom: -85px;
                }
            }
        }

        @media screen and (max-width: 600px) and (min-height: 500px) {
            height: 80vh;
        }

        @media screen and (max-width: 1050px) and (min-height: 1000px),
            (max-width: 600px) and (min-height: 800px) {
            height: 60vh;
        }
    }
}
