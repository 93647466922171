@import '../../../shared/common.scss';

.landing-wrapper {
    .access-control-section {
        padding: $landing-section-padding-top 2rem 15em
            2rem;

            

        background-image: linear-gradient(180deg, 
        rgba(17,18,18,1) 0%, 
        white 100px,
        white calc(100% - 200px),
        rgba(17,18,18,1) 100%);

        text-align: center;

        .title-section {
            margin-bottom: 6rem;
            h3 {
                @include section-title;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }

        .access-control-cards-wrapper {
            justify-content: center;
            flex-wrap: wrap;
            gap: 5rem;

            .access-control-card {
                h5 {
                    font-size: $card-title-font-size;
                }
                p {
                    line-height: 28.8px;
                    width: 340px;
                }
            }
        }

        @media screen and (max-width: 700px) {
            .title-section{
                h3{
                    font-size: 2.4rem;
                    line-height: 44px;
                }
            }
        }

        @media screen and (max-width: 460px) {
            .title-section{

                .description{
                    max-width: 350px;
                    margin: 0 auto;
                }
            }
        }
    }
}
