@import '../../../shared/common.scss';

.landing-wrapper {
    .monitoring-section {
        background-color: $background-color-dark-grey;
        color: $text-color-light;
        padding: $landing-section-padding-top 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(
            180deg,
            rgba(17, 18, 18, 1) calc(100% - 100px),
            white 100%
        );

        .video-wrapper {
            width: 100%;
        }

        .title-section {
            margin-bottom: 2rem;

            h3 {
                @include section-title;
                font-weight: 500;
                margin-bottom: 2rem;
            }

            p {
                text-align: center;
                font-weight: 300;
                max-width: 450px;
                margin: 0 auto;
            }
        }

        .content {
            height: 1px;
            width: 100%;
            max-width: 1200px;

            tr:nth-child(1) {
                height: 350px;
                display: none;
            }

            tr,
            td {
                height: 100%;
            }

            td {
                min-width: 100px;
                max-width: 650px;
                width: 50%;
            }
        }

        .monitoring-content {
            max-width: 850px;
            padding: 1rem;
        }

        .cards-section {
            justify-content: space-between;
            gap: 2rem;
            width: 100%;
            // padding-left: 1em;

            .monitoring-card {
                background: #363738;
                border-radius: 14px;
                align-items: center;
                padding: 7px 27px 10px 27px;
                justify-content: center;
                width: 240px;
                position: relative;

                .img-wrapper {
                    height: 35px;
                    width: 35px;
                }

                &:hover {
                    cursor: pointer;
                }

                &:hover + .monitoring-video {
                    transform: scale(1.1);
                }

                div:nth-child(1) {
                    margin-right: 1.2rem;
                }

                h5 {
                    font-size: $card-title-font-size;
                    // margin-bottom: 5px;
                }
            }

            .monitoring-card:nth-child(1) {
                h5 {
                    margin-right: 25px;
                }
            }

            .monitoring-card:nth-child(2) {
                h5 {
                    margin-top: 5px;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            .my-popover.monitoring {
                right: -90px;
            }
        }

        @media screen and (max-width: 1150px) {
            padding: 8rem 3rem;
        }

        
        @media screen and (max-width: 1020px) {
            .my-popover.monitoring {
                right: -45px;
                width: 200px;
            }
        }


        @media screen and (max-width: 850px) {
            .cards-section {
                .monitoring-card {
                    h5 {
                        font-size: 18px;
                        margin-top: 10px;
                    }
                }
            }

            table.content {
                max-width: 450px;
            }
            .content {
                tr:nth-child(1) {
                    display: table-row;
                    td {
                        padding-bottom: 2rem;
                    }
                }

                tr:nth-child(2) {
                    td:nth-child(1) {
                        display: none;
                    }
                }
            }

            // .cards-section {
            //     padding: 0;

            //     .monitoring-card {
            //         padding: 1.2rem;
            //         h5 {
            //             font-size: 20px;
            //             margin-top: 10px;
            //         }
            //     }
            // }
        }

        @media screen and (max-width: 655px) {
            .cards-section {
                .monitoring-card {
                    h5 {
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }
            }
        }

        @media screen and (max-width: 585px) {
            .cards-section {
                flex-direction: column;
                .monitoring-card {
                    width: 100%;
                    justify-content: center;
                }
            }

            .monitoring-card:nth-child(1) {
                h5 {
                    margin-right: 38px;
                }
            }
        }

        @media screen and (max-width: 550px) {
            .title-section {
                h3 {
                    font-size: 2.2rem;
                    line-height: 30px;
                }

                p {
                    max-width: 400px;
                    margin: 0 auto;
                    font-size: 1.2rem;
                }
            }
        }

        @media screen and (max-width: 420px) {
            .title-section {
                h3 {
                    font-size: 1.7rem;
                }

                p {
                    font-size: 1rem;
                }
            }
        }
    }
}
