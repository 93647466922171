@import '../../../shared/common.scss';

.landing-wrapper {
    .key-features-section {
        padding: 8rem 10px;
        background-image: linear-gradient(
            180deg,
            #0000 calc(100% - 300px),
            #111213 100%
        );

        h3 {
            margin-bottom: 4rem;
        }

        .white-overlay.hidden {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .video-wrapper {
            position: absolute;
            @include absoluteCenterPosition;
        }

        .cards-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            max-width: 1200px;
            margin: 0 auto;
        }

        .card {
            position: relative;
            overflow: hidden;
            border-radius: 13px;

            img {
                width: 100%;
                transition: transform 0.3s;
            }

            p {
                text-align: center;
                position: absolute;
                @include absoluteCenterPosition;
                font-size: 31px;
                color: $text-color-light;
            }

            &:hover {
                cursor: pointer;

                img {
                    transform: scale(1.2);
                }
            }
        }

        .card:nth-child(3) {
            white-space: nowrap;

            img {
                border-radius: 5px;
            }
        }

        .btn-wrapper {
            justify-content: center;
            margin-top: 100px;

            a {
                line-height: 27.24px;
                font-size: 13px;
            }
        }

        .key-features-mobile-card {
            display: none;
        }

        @media screen and (max-width: 720px) {
            .cards-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .card {
                width: 280px;
            }
            .card:nth-child(9) {
                display: none;
            }

            .key-features-mobile-card {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 720px) {
            .card {
                width: 240px;
            }
        }

        @media screen and (max-width: 530px) {
            .cards-wrapper {
                gap: 10px;
            }
            .card {
                width: 200px;
            }
        }

        @media screen and (max-width: 450px) {
            .card {
                width: 170px;
            }
        }

        @media screen and (max-width: 380px) {
            .card {
                width: 140px;
            }
        }
    }
}

.video-popup {
    .video-wrapper {
        width: 80%;
    }
    .video-wrapper > div {
        width: 100% !important;
        height: auto !important;
    }
}
