@import '../../shared/common.scss';

.add-setup-section {
    justify-content: center;
    align-items: center;

    form{
        padding: 1rem;
        @include cardBorder;
        background-color: $card-background;
        
        .input-wrapper {
            flex-direction: column;
        }
    }


    .form-wrapper {
        width: 30%;
    }
}