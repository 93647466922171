.login-page-wrapper {
    background-color: #151617;
    width: 100vw;
    height: 100vh;
    color: #fff;
    .login-form {
        width: 400px;
        border-radius: 16px;
        padding: 25px;
        background: #34373a;
    }

  .form-header {
    font-weight: bold;
    text-align: center;
    border-bottom: #959595  solid 0.5px;

    p {
        padding-bottom: 10px;
        font-size: 19px;
    }
  }

  label {
    font-size: 13px;
    color: #fff;
  }

  .select-wrapper {
    flex-grow: 1;
  }
}
