@import '../../shared/common.scss';

.dashboard {
    color: $text-color-light;
    display: flex;
    flex-direction: column;
    // height: 100%;
    height: calc(100vh - 150px - 300px);

    h3 {
        font-size: 2.7rem;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .flex-grow-wrapper {
        flex-grow: 1;
    }

    .cards-wrapper {
        gap: 25px;
        flex-wrap: wrap;
    }

    button {
        border-radius: 7px;
    }
}
