.create-setup-form {
    flex-direction: column;
    gap: 20px;

    .MuiFormControlLabel-root {
        margin: 0;
    }

    .MuiCheckbox-root {
        padding: 0 9px 0 0;
    }

    [aria-disabled='true'] {
        .css-i4bv87-MuiSvgIcon-root,
        .MuiFormControlLabel-label {
            color: #545657;
        }
    }

    .Mui-disabled {
        color: #545657 !important;
    }

    button {
        border-radius: 8px;
    }

    .shelly-input {
        flex-grow: 1;
    }

    .register-popup-link {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: #d5d5d5;
        }
    }

    .terms-label,
    .subscribe-label {
        font-weight: 300;
        font-size: 13px;
    }

    @media screen and (min-width: 500px) {
        min-width: 350px;
    }
}
