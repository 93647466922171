@import '../../shared/common.scss';

.landing-wrapper {
    .section-title{
        @include section-title;
        font-weight: 600;
    }

    .popup-overlay {
        
    }
}
