@import './shared/common.scss';

// html{
//     $root-font-size
// }

body {
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: $font-main;
}

.flex {
    display: flex;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: #000;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    flex-direction: column;
}

// .auth-setting-popup,
// .register-popup,
// .add-remove-domain-popup,
// .roles-and-access-popup
// {

//     .MuiBackdrop-root {
//         background-color: rgba(98, 98, 98, 0.5);
//     }
// }

.auth-setting-popup,
.register-popup,
.add-remove-domain-popup,
.contact-us-wrapper,
.roles-and-access-popup {
    .MuiPaper-root {
        background-color: $background-color-dark-grey;
        border-radius: 10px;
    }
    .MuiBackdrop-root {
        background-color: $popup-overlay-color;
    }
}

.popup-white-overlay {
    .MuiBackdrop-root {
        background-color: $popup-overlay-color;
    }
}

.white-overlay {
    @include white-overlay;
}

.key-features-popup .MuiPaper-root {
    overflow: hidden;
}

.popup-content-wrapper {
    padding: 10px;
}

.error-msg {
    position: absolute;
    bottom: -17px;
    font-size: 0.7rem;
    left: 5px;
    color: #dd3737;
}

.input-wrapper,
.checkbox-wrapper {
    position: relative;
}

.btn-blue {
    @include btn-blue;
}

.btn-blue-white {
    @include btn-blue-white;
}

.btn-white {
    @include btn-white;
}

.btn-white-outlined {
    @include btn;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    &:hover {
        // border: 1px solid #363738;
        background-color: #434344;
    }
}

.btn-black {
    @include btn-black;
}

.form-btn {
    @include form-btn;
}

.red-form-btn {
    @include red-form-btn;
}

.description {
    font-size: $description-font-size;
}

.input {
    @include input;
}

.card-popup-wrapper {
    max-width: 300px;
    padding: 10px;
}

@media screen and (max-width: 1250px), (max-height: 820px) {
    html {
        font-size: 14px;
    }
}

.delete-btn {
    @include delete-btn;
}

.cancel-btn {
    @include cancel-btn;
}

.MuiSnackbar-root {
    .MuiPaper-elevation {
        background-color: #0671dd;
    }
}

textarea {
    resize: none;
}

.grow-1 {
    flex-grow: 1;
}
.label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 5px;
}

.select-wrapper {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: 'Open Sans', sans-serif !important ;
        font-size: 15px;
        font-weight: 300;
    }
    .MuiInputBase-root {
        color: #fff;
    }
    fieldset {
        border: none;
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 8px;
    }

    .MuiSelect-outlined {
        padding: 8px 32px 9px 13px;
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
        .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover
        .MuiOutlinedInput-notchedOutline {
        border: 1px solid #fff !important;
    }

    fieldset {
        border: 1px solid #fff;

        &:hover {
        }
    }

    .Mui-focused {
        border: none;
        border: 1px solid #fff !important;
    }

    .MuiSvgIcon-root {
        color: #fff;
    }
}

.checkbox-input {
    svg {
        color: #1976d2;
    }
}

.hover-pointer {
    &:hover {
        cursor: pointer;
    }
}

.icon-blue {
    color: $accent-text-blue-color-dark;
}

.icon-error {
    color: $error-color;
}

.icon-grey {
    color: #bbb8b8;
}

.grow-1 {
    flex-grow: 1;
}