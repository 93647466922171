@import '../../../shared/common.scss';

.landing-wrapper {
    .download-app-section {
        padding: 3rem 0 0 9rem;
        // padding-bottom: calc($landing-section-padding-top * 2);
        min-height: 100vh;

        background-image: linear-gradient(
            180deg,
            white calc(100% - 100px),
            rgba(17, 18, 18, 1) 100%
        );

        .content,
        .img-wrapper {
            width: 50%;
        }

        .content {
            margin-top: 11rem;
            h3 {
                font-size: 4.2rem;
                text-align: left;
                font-weight: 700;
                line-height: 87px;
            }

            p {
                white-space: nowrap;
            }
        }

        .links-wrapper {
            margin-top: 4rem;
            gap: 20px;
        }
        .app-link:nth-child(1) {
            width: 200px;
        }

        .app-link:nth-child(2) {
            width: 258px;
        }

        .app-link {
            img {
                width: 100%;
            }
        }

        .images-wrapper {
            img {
                width: 100%;
            }
        }

        @media screen and (max-width: 1450px) {
            padding-left: 7rem;
        }

        @media screen and (max-width: 1250px) {
            flex-direction: column;
            align-items: center;
            padding: 2rem;

            .app-link:nth-child(1) {
                width: 43%;
            }

            .app-link:nth-child(2) {
                width: 55%;
            }

            .content {
                width: auto;

                p {
                    white-space: initial;
                }
            }
            img {
                width: 100%;
            }
        }

        @media screen and (max-width: 520px) {
            .content {
                h3 {
                    font-size: 3.6rem;
                }
            }
        }

        @media screen and (max-width: 410px) {
            .content {
                h3 {
                    font-size: 2.8rem;
                }

                p {
                    font-size: 1rem;
                }
            }
        }

        @media screen and (max-width: 370px) {
            .links-wrapper{
                flex-direction: column;
                align-items: center;

                .app-link:nth-child(1){
                    width: 87%;
                }

                .app-link:nth-child(2){
                    width: 100%;
                }
            }
        }
    }
}
