@import '../../shared/common.scss';

.contacts-page-wrapper {
    padding-top: $landing-page-top-padding;

    .content-wrapper {
        max-width: 900px;
        margin: 50px auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .text-blue {
        color: $shelly-blue;
    }

    .bold {
        font-weight: 600;
    }

    h2 {
        font-size: 45px;
        font-weight: 400;
    }
}