.requestFormWrapper {
    background-color: #111213;
    padding: 30px;

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        label {
            color: #fff;
        }

        button {
            border-radius: 8px;
        }
    }

    .css-i4bv87-MuiSvgIcon-root {
        color: #1976d2;
      }

      a{
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: #d5d5d5;
        }
      }

      .email-sent-view {
        color: #fff;

        p {
            margin-bottom: 10px;
        }
      }
}