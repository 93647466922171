@import '../../../../shared/common.scss';

.plan-card-wrapper {
    background-color: $card-background;
    width: 299px;
    height: 550px;
    border-radius: 12px;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3.4rem 2rem;
    cursor: pointer;

    h4 {
        font-weight: 500;
        font-size: 1.7rem;
        line-height: 25px;
        letter-spacing: 0em;
    }

    h4{
        margin-bottom: 1.8rem;
    }

    .card-price-section {
        min-height: 80px;

        p:nth-child(1) {
            font-weight: 500;
            font-size: 2.5rem;
        }

        p:nth-child(2) {
            font-weight: 300;
        }
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .card-content {
        flex-grow: 1;
    }

    .plan-li-wrapper {
        gap: 10px;
    }

    li {
        list-style: none;
        font-size: 1rem;
    }

    .choose-plan-btn {
        width: 90%;
        margin: 0 auto;
    }

    .tenants {
        text-align: center;
        margin-top: 10px;
    }

    transition: all 0.3s;
}

.plan-card-wrapper:not(.selected-card-wrapper):hover {
    box-shadow: 2px 1px 27px 1px rgba(49,173,255,0.69);
    -webkit-box-shadow: 2px 1px 27px 1px rgba(49,173,255,0.69);
    -moz-box-shadow: 2px 1px 27px 1px rgba(49,173,255,0.69);
}


.selected-card-wrapper {
    height: 580px;
    background: $selected-card-background;
    padding-bottom: calc(2.4rem + 29px);
    padding-top: calc(20px + 29px);
}

.selected-card-wrapper:nth-child(4){
    padding-bottom: calc(2rem + 29px);
}